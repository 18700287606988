<template>
  <component
    :is="componentType"
    :id="tagId"
    v-editable="blok"
    :class="[
      customMarginClass,
      paragraphBoldClass,
      fontStyleClass,
      textColorClass,
      narrowTextClass,
      positionMobileClass,
      positionClass,
    ]"
    :style="[maxWidth]"
  >
    {{ blok.text !== undefined && decodeAndReplaceText(blok.text) }}
  </component>
</template>

<script lang="ts" setup>
import type { SbSimpleTextStoryblok } from '@/types'
import { slugify } from '@/utils/helpers'

interface Props {
  blok: SbSimpleTextStoryblok
}
const { decodeAndReplaceText } = useTextModifiers()
const props = defineProps<Props>()
const componentType = computed(() => props.blok.tag_type || 'p')
const paragraphBoldClass = computed(
  () => props.blok.is_bold && 'paragraph--bold',
)
const customMarginClass = computed(
  () => `mb-${props.blok?.custom_margin}` ?? '',
)
const fontStyleClass = computed(() => props.blok.font?.font?.value ?? '')
const textColorClass = computed(() =>
  props.blok?.font?.color_name ? `text-${props.blok.font.color_name}` : 'none',
)
const narrowTextClass = computed(() => (props.blok.narrow ? 'text-narrow' : ''))
const positionClass = computed(
  () => `text-position--${props.blok.position}` || 'text-position--left',
)
const positionMobileClass = computed(
  () => `text-position-mobile--${props.blok.position_mobile}` || null,
)
const maxWidth = computed(() => {
  if (props.blok?.max_width) {
    return {
      maxWidth: `${props.blok.max_width}px`,
    }
  }

  return {
    maxWidth: 'none',
  }
})
const tagId = computed(() => {
  if (props.blok.tag_type === 'h2' || props.blok.tag_type === 'h3') {
    return slugify(decodeAndReplaceText(props.blok.text as string))
  }
  return null
})
</script>

<style lang="scss" scoped>
.text-pill {
  &::after {
    display: inline-flex;
    transform: translateY(-10%);
    content: attr(data-visual-label);
    background-color: var(--cobalt-800);
    padding: 2px 8px;
    border-radius: 50vh;
    color: var(--white);
    font-size: 11px;
    line-height: 16px;
    margin-left: 8px;
    text-transform: uppercase;
    align-items: center;
    justify-content: center;
    font-style: normal;
    font-weight: 600;
    width: fit-content;
  }
}

.text-narrow {
  max-width: 660px;
  margin: 0 auto;
}

.text-position {
  margin-left: 0;
  margin-right: 0;

  &--center {
    text-align: center;
  }

  &--left {
    text-align: left;
  }

  &--right {
    text-align: right;
  }
}

.text-position-mobile {
  &--center {
    @media (max-width: $breakpoint-lg) {
      text-align: center;
    }
  }

  &--left {
    @media (max-width: $breakpoint-lg) {
      text-align: left;
    }
  }

  &--right {
    @media (max-width: $breakpoint-lg) {
      text-align: right;
    }
  }
}
</style>
